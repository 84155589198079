const RoleLink = () =>
    import("@/components/RoleLink");
const RenewBook = () =>
    import("@/components/Member/RenewBook");
const ReserveBookM = () =>
    import("@/components/Member/ReserveBookM");
const BookDonation = () =>
    import("@/components/Member/BookDonation");
const searchcatlogmemeber = () =>
    import("@/components/Member/SearchCatalogMember");
    const viewfine = () =>
    import("@/components/Member/ViewFine");

const routes = [{
    path: "/rolelink",
    name: "rolelink",
    component: RoleLink,
    meta: {
        breadcrumb: "Login" //crumb
    },
    children: [{
        path: '/member-renew-book',
        name: 'member-renew-book',
        component: RenewBook,
    },
    {
        path: '/search-catlog-member',
        name: 'search-catlog-member',
        component: searchcatlogmemeber,
    },
    {
        path: '/reserve-book-student',
        name: 'reserve-book-student',
        component: ReserveBookM,
    },
    {
        path: '/book-donation',
        name: 'book-donation',
        component: BookDonation,
    },
    {
        path: '/viewfine',
        name: 'viewfine',
        component: viewfine,
    }
    ]

}];
export default routes;