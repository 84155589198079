const AdminMaster = () =>
    import("@/components/Admin/AdminMasterMain");
const ImportFile = () =>
    import("@/components/Admin/ImportFile");
const AddUser = () =>
    import("@/components/Admin/AddUser");
const AddNewUser = () =>
    import("@/components/Admin/AddNewUser");
const LibraryConfig = () =>
    import("@/components/Admin/LibraryCofig");
const AssignRole = () =>
    import("@/components/Admin/AssignRole");
const useractive_inactive = () =>
    import("@/components/Admin/useractive&inactive");

const RoleLink = () =>
    import("@/components/RoleLink");
const SearchCatalogHome = () =>
    import("@/components/Admin/SearchCatalogHome2");
const BookItemHome = () =>
    import("@/components/Admin/BookItemHome");
const UserTypeMaster = () =>
    import("@/components/Admin/AdminMaster/UserTypeMaster");

const Titel = () =>
    import("@/components/Admin/AdminMaster/Title");

const BookClassification = () =>
    import("@/components/Admin/AdminMaster/BookClassification");

const Publisher = () =>
    import("@/components/Admin/AdminMaster/Publisher");
const Author = () =>
    import("@/components/Admin/AdminMaster/Author");

    const BookBill = () =>
    import("@/components/Admin/AdminMaster/BookBill");

const BookType = () =>
    import("@/components/Admin/AdminMaster/BookType");
const Role = () =>
    import("@/components/Admin/AdminMaster/Role");
const BookCategory = () =>
    import("@/components/Admin/AdminMaster/BookCategory");
    const Course = () =>
    import("@/components/Admin/AdminMaster/Course");
const RoleLinks = () =>
    import("@/components/Admin/AdminMaster/RoleLinks");
const Employee = () =>
    import("@/components/Admin/ImportCSVFile/Employee");
const Member = () =>
    import("@/components/Admin/ImportCSVFile/Member");
const LibraryPolicy = () =>
    import("@/components/Admin/LibraryPolicy");
const BookFormat = () =>
    import("@/components/Admin/AdminMaster/BookFormat");
const BookStatus = () =>
    import("@/components/Admin/AdminMaster/BookStatus");
//madhuri
const Weedoutcode = () =>
    import("@/components/Admin/AdminMaster/Weedoutcode");
const ReservationStatus = () =>
    import("@/components/Admin/AdminMaster/ReservationStatus");
const AccountStatus = () =>
    import("@/components/Admin/AdminMaster/AccountStatus");
const Frequency = () =>
    import("@/components/Admin/AdminMaster/Frequency");
const LibraryPolicyType = () =>
    import("@/components/Admin/AdminMaster/LibraryPolicyType");
const Language = () =>
    import("@/components/Admin/AdminMaster/Language");
const BookCondition = () =>
    import("@/components/Admin/AdminMaster/BookCondition");
const Currency = () =>
    import("@/components/Admin/AdminMaster/Currency");
const BooksVendors = () =>
    import("@/components/Admin/AdminMaster/BooksVendors");
const TransactionType = () =>
    import("@/components/Admin/AdminMaster/TransactionType");
const NotificationType = () =>
    import("@/components/Admin/AdminMaster/NotificationType");
const Rack = () =>
    import("@/components/Admin/AdminMaster/Rack");
const NotificationMethod = () =>
    import("@/components/Admin/AdminMaster/NotificationMethod");
const NotificationTemplate = () =>
    import("@/components/Admin/AdminMaster/NotificationTemplate");
const Organization = () =>
    import("@/components/Admin/Organization");
const AddLibrary = () =>
    import("@/components/Admin/AddLibrary");
const bulk_delete = () =>
    import("@/components/Admin/bulk_delete");
const Program = () =>
    import("@/components/Admin/AdminMaster/Program");
const ProgramType = () =>
    import("@/components/Admin/AdminMaster/ProgramType");
const PhysicalDetails = () =>
    import("@/components/Admin/AdminMaster/PhysicalDetails");
const AccompanyingMaterials = () =>
    import("@/components/Admin/AdminMaster/AccompanyingMaterials");
const Series = () =>
    import("@/components/Admin/AdminMaster/Series");
const ERPDepartment = () =>
    import("@/components/Admin/AdminMaster/ERPDepartment");
const BookVendorType = () =>
    import("@/components/Admin/AdminMaster/BookVendorType");
const BookVendor = () =>
    import("@/components/Admin/AdminMaster/BookVendor");
const LibraryDepartment = () =>
    import("@/components/Admin/AdminMaster/LibraryDepartment");
const Shift = () =>
    import("@/components/Admin/AdminMaster/Shift");
const Semester = () =>
    import("@/components/Admin/AdminMaster/Semester");
const BookEdition = () =>
    import("@/components/Admin/AdminMaster/BookEdition");
const Year = () =>
    import("@/components/Admin/AdminMaster/Year");
const AcademicYear = () =>
    import("@/components/Admin/AdminMaster/AcademicYear");
const LearnerDivisionSynchERP = () =>
    import("@/components/Admin/LearnerDivisionSynchERP");
const routes = [
    {
        path: '/search-catalog-home',
        name: 'search-catalog-home',
        component: SearchCatalogHome,
    },
    {
        path: '/book-item-home',
        name: 'book-item-home',
        component: BookItemHome,
    },
    {
        path: "/rolelink",
        name: "rolelink",
        component: RoleLink,
        meta: {
            breadcrumb: "Login" //crumb
        },


        children: [{
            path: '/admin-master',
            name: 'admin-master',
            component: AdminMaster,
        },
        {
            path: '/imoprt-file',
            name: 'import-file',
            component: ImportFile,
        },
        {
            path: '/LearnerDivisionSynchERP',
            name: 'LearnerDivisionSynchERP',
            component: LearnerDivisionSynchERP,
        },
        {
            path: '/add-user',
            name: 'add-user',
            component: AddUser,
        },
        {
            path: '/add-new-user',
            name: 'add-new-user',
            component: AddNewUser,
        },
        {
            path: '/lib-config',
            name: 'lib-config',
            component: LibraryConfig,
        },
        {
            path: '/bulk-delete',
            name: 'bulk-delete',
            component: bulk_delete,
        },
        {
            path: '/assign-role',
            name: 'assign-role',
            component: AssignRole,
        },
        {
            path: '/useractive_inactive',
            name: 'useractive_inactive',
            component: useractive_inactive,
        },
        {
            path: '/library-policy',
            name: 'library-policy',
            component: LibraryPolicy,
        },
        {
            path: 'admin-master/user-type',
            name: 'user-type',
            component: UserTypeMaster,
        },
        {
            path: 'admin-master/book-classification',
            name: 'book-classification',
            component: BookClassification,
        },
        {
            path: 'admin-master/publisher',
            name: 'publisher',
            component: Publisher,
        },
        {
            path: 'admin-master/author',
            name: 'author',
            component: Author,
        },
        {
            path: 'admin-master/book-bill',
            name: 'book-bill',
            component: BookBill,
        },
        {
            path: 'admin-master/erp-department',
            name: 'erp-department',
            component: ERPDepartment,
        },

        {
            path: 'admin-master/booksVendors',
            name: 'booksVendors',
            component: BooksVendors,
        },

        {
            path: 'admin-master/Title',
            name: 'Title',
            component: Titel,
        },



        {
            path: 'admin-master/book-type',
            name: 'book-type',
            component: BookType,
        },
        {
            path: 'admin-master/vendor-type',
            name: 'vendor-type',
            component: BookVendorType,
        },
        {
            path: 'admin-master/vendor',
            name: 'vendor',
            component: BookVendor,
        },
        {
            path: 'admin-master/library-department',
            name: 'library-department',
            component: LibraryDepartment,
        },


        {
            path: 'admin-master/role',
            name: 'role',
            component: Role,
        },
        {
            path: 'admin-master/book-category',
            name: 'book-category',
            component: BookCategory,
        },
        {
            path: 'admin-master/course',
            name: 'course',
            component: Course,
        },
        {
            path: 'admin-master/role-liks-master',
            name: 'role-liks-master',
            component: RoleLinks,
        },
        {
            path: 'import-csv-file/employee',
            name: 'employee',
            component: Employee,
        },
        {
            path: 'import-csv-file/member',
            name: 'member',
            component: Member,
        },
        {
            path: 'admin-master/book-format',
            name: 'book-format',
            component: BookFormat,
        },
        {
            path: 'admin-master/book-status',
            name: 'book-status',
            component: BookStatus,
        },
        //madhuri
        {
            path: 'admin-master/weedoutcode',
            name: 'weedoutcode',
            component: Weedoutcode,
        },
        {
            path: 'admin-master/reservation-status',
            name: 'reservation-status',
            component: ReservationStatus,
        },
        {
            path: 'admin-master/account-status',
            name: 'account-status',
            component: AccountStatus,
        },
        {
            path: 'admin-master/frequency',
            name: 'frequency',
            component: Frequency,
        },
        {
            path: 'admin-master/libraryPolicyType',
            name: 'libraryPolicyType',
            component: LibraryPolicyType,
        },
        {
            path: 'admin-master/Language',
            name: 'Language',
            component: Language,
        },
        {
            path: 'admin-master/book-condition',
            name: 'book-condition',
            component: BookCondition,
        },
        {
            path: 'admin-master/currency',
            name: 'currency',
            component: Currency,
        },
        {
            path: 'admin-master/notification-type',
            name: 'notification-type',
            component: NotificationType,
        },
        {
            path: 'admin-master/transaction-type',
            name: 'transaction-type',
            component: TransactionType,
        },
        {
            path: 'admin-master/notification-method',
            name: 'notification-method',
            component: NotificationMethod,
        },
        {
            path: 'admin-master/rack',
            name: 'rack',
            component: Rack,
        },
        {
            path: 'admin-master/notification-template',
            name: 'notification-template',
            component: NotificationTemplate,
        },
        {
            path: '/org',
            name: 'org',
            component: Organization,
        },
        {
            path: '/add-library',
            name: 'add-library',
            component: AddLibrary,
        },
        {
            path: 'admin-master/program',
            name: 'program',
            component: Program,
        },
        {
            path: 'admin-master/programType',
            name: 'programType',
            component: ProgramType,
        },
        {
            path: 'admin-master/physicalDetails',
            name: 'physicalDetails',
            component: PhysicalDetails,
        },
        {
            path: 'admin-master/accompanyingMaterials',
            name: 'accompanyingMaterials',
            component: AccompanyingMaterials,
        },
        {
            path: 'admin-master/Series',
            name: 'Series',
            component: Series,
        },
        {
            path: 'admin-master/AcademicYear',
            name: 'AcademicYear',
            component: AcademicYear,
        },
        {
            path: 'admin-master/Year',
            name: 'Year',
            component: Year,
        },
        {
            path: 'admin-master/Semester',
            name: 'Semester',
            component: Semester,
        },
        {
            path: 'admin-master/BookEdition',
            name: 'BookEdition',
            component: BookEdition,
        },
        {
            path: 'admin-master/Shift',
            name: 'Shift',
            component: Shift,
        },

        ]

    }];
export default routes;